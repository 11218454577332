<template>
  <div class="home">
    <v-snackbar top v-model="snackbar">{{ snackbarText }}</v-snackbar>
    <div class="nav" v-if="order && !hideLogo">
      <Logo height="70%" />
      <descarga className="btn btn-dark">
        <img src="@/assets/pulpito.svg" slot="pulpito" class="mr-2" width="30px" alt="" />
      </descarga>
    </div>
    <div class="mapa">
      <Mapa v-if="order" :order="order" />
      <div>
        <img v-if="order == null" class="mb-6 heartbeat" :src="motorista" width="100%" alt="" srcset="" />
        <h1 v-if="order == null">{{ message }}</h1>
        <v-form>
          <v-text-field v-if="codeOrder && order == null" label="Código de tracking" class="mb-4"
            style="border-radius: 15px !important" outlined filled v-model="shortId" full-width
            onkeyup="javascript:this.value=this.value.toUpperCase();" rounded
            :messages="['ingresa el código de tracking']"></v-text-field>
          <v-btn v-if="codeOrder && order == null" color="#ff5900" style="border-radius: 15px !important" dark
            @click="getLocations" height="55px" block>Continuar</v-btn>
        </v-form>
      </div>
    </div>

    <div class="actions-btn" v-if="order">
      <v-btn fab dark color="#00cdbc" :href="`tel:${order.driver.phone}`">
        <v-icon small>fa-solid fa-phone</v-icon>
      </v-btn>
      <v-btn fab dark color="#25D366" @click="openWh">
        <v-icon> fa-brands fa-whatsapp </v-icon>
      </v-btn>
      <!-- <v-btn v-else fab dark color="#25D366"
        :href="`https://wa.me/${order.driver.phone}/?text=Hola, tengo una consulta de mi orden.`">
        <v-icon> fa-brands fa-whatsapp </v-icon>
      </v-btn> -->

    </div>
    <v-chip class="status" :color="colorStatus" v-if="order">
      <b style="color: #fff">{{
      order.progressCurrentStatus | statusFilter
    }}</b>
    </v-chip>
    <v-card class="floating-info" v-if="!menuOpen && order" @click="menuOpenfn">
      <v-card-text>
        <div class="texto">
          <v-icon class="icon-info" right color="#FFC634">fa-solid fa-circle-info</v-icon>

          <div>
            <h4 class="font-weight-medium">
              Orden <span class="color-ocho">{{ shortId }}</span>
            </h4>
            <h4 class="font-weight-medium ma-0">Motorista</h4>
            <p class="text-caption">{{ order.driver.name }}</p>
          </div>
          <div>
            <p class="pa-0 ma-0 font-weight-bold">
              {{ order.driverEstDeliveryDateTime }}
            </p>
            <h4 class="text-caption">Hora estimada de entrega</h4>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <transition name="slide-fade">
      <v-card class="menu pt-10" v-if="menuOpen">
        <v-chip class="status-2" :color="colorStatus">
          <b style="color: #fff">{{
      order.progressCurrentStatus | statusFilter
    }}</b>
        </v-chip>

        <v-btn color="#ff5900" dark fab right absolute top small left @click="menuOpen = false">
          <v-icon color="#fff"> mdi-close </v-icon>
        </v-btn>
        <v-row class="contact">
          <v-col cols="12" class="flex-center">
            <Contact :order="order" />
          </v-col>
          <v-col cols="12">
            <h4 class="font-weight-medium">
              Orden <span class="color-ocho">{{ shortId }}</span>
            </h4>
            <p class="text-caption mb-0">{{ order.businessName }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h4 class="font-weight-medium" v-if="order.userName">Cliente</h4>
            <p class="text-caption" v-if="order.userName">
              {{ order.userName }}
            </p>

            <h4 class="font-weight-medium">Hora estimada de entrega</h4>
            <p class="text-caption">{{ order.driverEstDeliveryDateTime }}</p>

            <h4 class="font-weight-medium" v-if="order.address">Dirección</h4>
            <p class="text-caption" v-if="order.address">
              {{ order.address }}
            </p>
          </v-col>
        </v-row>
      </v-card>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src

import Logo from "@/components/logo";
import Mapa from "@/components/mapa";
import Contact from "@/components/contact";
import axios from "axios";
import descarga from "@/components/btnDescarga";

export default {
  name: "Home",
  components: {
    Logo,
    Mapa,
    Contact,
    descarga,
  },
  data() {
    return {
      motorista: require("@/assets/driver-02.svg"),
      fab: false,
      fab_: false,
      menuOpen: false,
      codeOrder: false,
      showMap: false,
      hideLogo: false,
      shortId: "",
      order: null,
      completada: null,
      snackbar: false,
      snackbarText: "",
      message: "Tracking no disponible",
    };
  },
  computed: {
    colorStatus() {
      switch (this.order.progressCurrentStatus) {
        case "placed":
          return "#A9C1FC";
          break;

        case "accepted":
          return "#ADF3F6";
          break;

        case "enRoute":
          return "#FFC634";
          break;

        case "onDelivery":
          return "#00cdbc";
          break;

        case "delivered":
          return "#ff5900";
          break;

        case "completed":
          return "#BBE5FF";
          break;

        case "canceled":
          return "#E22323";
          break;

        case "rejected":
          return "#E22323";
          break;

        default:
          return "#E22323";
          break;
      }
    },
  },
  methods: {
    menuOpenfn() {
      this.menuOpen = !this.menuOpen;
    },

    openWh() {
      let phone = this.order.driver.phone
        .replace("+", "")
        .replaceAll(" ", "")
        .replaceAll("-", "");

      window.open(
        `https://wa.me/${phone}/?text=Hola, tengo una consulta de mi orden.`,
        "_top"
      );
    },
    getLocations() {
      axios
        .get(`${process.env.VUE_APP_TRACKING_URL}?id=${this.shortId}`)
        .then((res) => {
          this.order = res.data;


          if (this.order.progressCurrentStatus == "onDelivery") {
            this.order.points = this.order.points.filter((x, i) => i != 1);
          }
          setTimeout(() => {
            this.showMap = true;
          }, 2000);
        })
        .catch((err) => {
          this.message = err.response.data.message ?? this.message;
          console.log(this.message);

          if (err.response.data.status) {
            this.completada = true;
          } else {
            this.completada = false;
          }
        });

      setInterval(() => {
        axios
          .get(`${process.env.VUE_APP_TRACKING_URL}?id=${this.shortId}`)

          // .get(
          //   `https://us-central1-el-ocho.cloudfunctions.net/httpTracking?id=${this.shortId}`
          // )
          .then((res) => {
            this.order = res.data;

            if (this.order.progressCurrentStatus == "onDelivery") {
              this.order.points = this.order.points.filter((x, i) => i != 1);
            }
          })
          .catch((err) => {
            this.message = err.response.data.message ?? this.message;
            console.log(this.message);

            if (err.response.data.status) {
              this.completada = true;
            } else {
              this.completada = false;
            }
            this.order = null;
          });
      }, 20000);
    },
  },
  filters: {
    statusFilter: function (status) {
      switch (status) {
        case "placed":
          return "Colocada por el Cliente";
          break;

        case "accepted":
          return "Aceptada por el Comercio";
          break;

        case "enRoute":
          return "Chavo en Camino al Comercio";
          break;

        case "onDelivery":
          return "Chavo en Camino al Cliente";
          break;

        case "delivered":
          return "Entregada al Cliente";
          break;

        case "completed":
          return "Orden Completada";
          break;

        case "canceled":
          return "Cancelada por Ocho";
          break;

        case "rejected":
          return "Rechazada por el Negocio";
          break;

        default:
          return "Desconocido";
          break;
      }
    },
  },
  async mounted() {
    // console.log(this.$route.query.hideLogo);
    if (this.$route.query && this.$route.query.hideLogo == "true") {
      this.hideLogo = true;
    }

    if (this.$route.params.id) {
      this.shortId = this.$route.params.id;
      this.getLocations();
    } else {
      this.codeOrder = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.actions-btn {
  z-index: 1000 !important;
  position: fixed;
  right: 10px;
  bottom: 17vh;
  display: flex;
  height: 120px;
  justify-content: space-between;
  flex-direction: column;
}

.leaflet-left {
  left: 0;
  display: none !important;
}

.home {
  height: 100%;
  width: 100%;
  position: relative;
  color: #202e4f;
}

.mapa {
  left: 0;
  height: 100%;
  width: 100vw;
  top: 0px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.menu {
  min-height: 60vh !important;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  z-index: 100;
  padding: 20px;
  bottom: 0px;
  display: flex;
  transition: 0.3s ease-in-out;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  z-index: 1000 !important;
  box-shadow: #00174d4a 0px 0px 20px;
}

.nav {
  position: fixed;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 8vh;
  background: #fff;
  box-shadow: #202e4f1c 0px 10px 10px;
}

.color-ocho {
  color: #ff5900;
}

.floating-info {
  width: calc(100% - 20px);
  // width: 100%;
  cursor: pointer !important;
  margin-left: 10px;
  height: calc(17vh - 30px);

  position: fixed;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: #0d132233 0px 10px 20px;
  z-index: 1000;
  bottom: 20px;

  align-items: center;

  .texto {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 40px 10px 15px;

    p {
      line-height: 10px;
    }

    .icon-info {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin: 0px !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-fade-enter-active {
  transition-delay: 1s;
  transition: 0.3s ease-in-out;
}

.slide-fade-leave-active {
  transition-delay: 1s;
  transition: 0.3s ease-in-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(100%);
  transition-delay: 1s;
  transition: 0.3s ease-in-out;
}

.status {
  background-color: #00cdbc;
  padding: 5px 10px;
  position: fixed;
  // bottom:calc(15vh -10px);
  bottom: calc(15vh + 20px);
  left: 10px;
  font-weight: 500 !important;
  border-radius: 10px;
  color: #fff;
  z-index: 1000;
}

.status-2 {
  background-color: #ff5900;
  padding: 5px 10px;

  margin: auto;
  border-radius: 10px;
  color: #fff;
}

.contact {
  width: 100%;
}

.heartbeat {
  -webkit-animation: heartbeat 3s ease-in-out infinite both;
  animation: heartbeat 3s ease-in-out infinite both;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
</style>
