<template>
  <l-map
    v-if="showMap"
    ref="myMap"
    :zoom="zoom"
    :center="center"
    :options="mapOptions"
    @update:zoom="zoomUpdate"
  >
    <l-marker
      v-for="(e, id) in order.points"
      :key="id"
      :lat-lng="[e.latitude, e.longitude]"
      className="marker-custom"
      :icon="
        id == 0
          ? iconDriver
          : order.progressCurrentStatus != 'onDelivery' && id == 1
          ? getImageComers
          : getImage
      "
    >
    </l-marker>
    <l-polyline
      :weight="2"
      :lat-lngs="coordinatesPolygons"
      color="#00cdbc"
      className="route"
    >
    </l-polyline>
    <l-tile-layer :url="url" />
    <!-- <l-control-zoom position="bottomright"></l-control-zoom> -->
    <div class="btn-refresh" @click="centerZoom">
      <v-btn fab dark color="#fff">
        <v-icon color="#ff5900"> fa-solid fa-location-crosshairs </v-icon>
      </v-btn>
    </div>
  </l-map>
</template>

<script>
import L, { point } from "leaflet";
import axios from "axios";
// import { OpenStreetMapProvider } from "leaflet-geosearch";
import { latLng } from "leaflet";
import {
  LMap,
  LTileLayer,
  LIcon,
  LMarker,
  LPopup,
  LControlZoom,
  LControl,
  LTooltip,
  LPolyline,
} from "vue2-leaflet";

require("leaflet-routing-machine");
require("leaflet-control-geocoder");

export default {
  name: "Mapa",
  props: ["order"],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    "l-icon": LIcon,
    "l-popup": LPopup,
    "l-control-zoom": LControlZoom,
    "l-tooltip": LTooltip,
    LPolyline,
  },
  data() {
    return {
      options: {},
      menuOpen: false,
      icon: L.icon(this.getImage),
      zoom: 10,
      center: latLng(14.0625764, -87.2226859),
      url: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",

      routeControl: "",
      driver: latLng(14.0670088, -87.2180904),
      currentZoom: 11.5,
      showParagraph: false,
      mapOptions: {
        zoomSnap: 1,
      },
      showMap: false,
      businessLogo: null,

      iconDriver: L.icon({
        className: "motorista",
        iconUrl: require("@/assets/driver-02.svg"),
        iconSize: [64, 64],
        iconAnchor: [32, 64],
      }),
      coordinatesPolygons: [],
    };
  },
  computed: {
    getImage() {
      return L.icon({
        iconUrl: require("@/assets/avatar-default.svg"),
        shadowUrl: require("@/assets/map-marker-01.svg"),
        iconSize: [32, 32],
        shadowSize: [64, 64],
        iconAnchor: [19, 53],
        shadowAnchor: [35, 60],
      });
    },

    statusOrder() {
      if (this.order.progressCurrentStatus == "onDelivery") {
        let points = this.order.points;
        points.splice(1, 1);
        this.order.points = points;
        this.getPoly();
      }
    },
    getImageComers() {
      return L.icon({
        iconUrl:
          this.order && this.order.businessLogo
            ? this.order.businessLogo.original
            : require("@/assets/avatar-default.svg"),
        shadowUrl: require("@/assets/map-marker-01.svg"),
        iconSize: [32, 32],
        shadowSize: [64, 64],
        iconAnchor: [19, 53],
        shadowAnchor: [35, 60],
      });
    },
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },
  },
  methods: {
    menuOpenfn() {
      this.menuOpen = !this.menuOpen;
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerZoom() {
      this.map = this.$refs.myMap.mapObject;

      let points = this.order.points.map((item) => {
        return latLng(item.latitude, item.longitude);
      });

      this.map.fitBounds(points, {
        paddingTopLeft: [23, 23],
        paddingBottomRight: [23, 23],
      });
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert("Click!");
    },
    // showPoints() {
    //   if (this.order.progressCurrentStatus == "onDelivery") {
    //     let points = this.order.points;
    //     points.splice(1, 1);
    //     // console.debug(points);
    //     points = this.order.points.map((item) => {
    //       return latLng(item.latitude, item.longitude);
    //     });

    //     this.routeControl.setWaypoints(points);
    //     this.routeControl.route();

    //     this.map.eachLayer((layer) => {
    //       if (layer.setIcon && layer._latlng) {
    //         layer.dragging.disable();
    //         if (
    //           points[0] &&
    //           layer._latlng.lat == points[0].lat &&
    //           layer._latlng.lng == points[0].lng
    //         ) {
    //           layer.setIcon(this.iconDriver);

    //           layer.options.draggable = false;
    //         } else if (
    //           points[1] &&
    //           layer._latlng.lat == points[1].lat &&
    //           layer._latlng.lng == points[1].lng &&
    //           this.order.orderType != "errand"
    //         ) {
    //           layer.setIcon(this.getImage);
    //         } else {
    //           layer.setIcon(this.getImage);
    //         }
    //       }
    //     });
    //   } else {
    //     let points = this.order.points.map((item) => {
    //       return latLng(item.latitude, item.longitude);
    //     });
    //     this.routeControl.setWaypoints(points);
    //     this.routeControl.route();
    //     this.map.eachLayer((layer) => {
    //       if (layer.setIcon && layer._latlng) {
    //         layer.dragging.disable();
    //         if (
    //           points[0] &&
    //           layer._latlng.lat == points[0].lat &&
    //           layer._latlng.lng == points[0].lng
    //         ) {
    //           layer.setIcon(this.iconDriver);

    //           layer.options.draggable = false;
    //         } else if (
    //           points[1] &&
    //           layer._latlng.lat == points[1].lat &&
    //           layer._latlng.lng == points[1].lng
    //           // &&
    //           // this.order.orderType != "errand"
    //         ) {
    //           layer.setIcon(this.getImageComers);
    //         } else if (
    //           points[2] &&
    //           layer._latlng.lat == points[2].lat &&
    //           layer._latlng.lng == points[2].lng &&
    //           this.order.orderType != "errand"
    //         ) {
    //           layer.setIcon(this.getImage);
    //         } else {
    //           layer.setIcon(this.getImage);
    //         }
    //       }
    //     });
    //   }
    // },
    async getPoly() {
      let pointsQuery = "";
      for (let i = 0; i < this.order.points.length; i++) {
        const point = this.order.points[i];
        pointsQuery += `${point.longitude},${point.latitude};`;
      }

      pointsQuery = pointsQuery.slice(0, pointsQuery.length - 1);
      let distance = await axios.get(
        `${this.order.drivingUrl}/${pointsQuery}?geometries=geojson`
      );
      this.coordinatesPolygons = distance.data.routes[0].geometry.coordinates;
      this.coordinatesPolygons = this.coordinatesPolygons.map((item) => {
        return [item[1], item[0]];
      });
    },
  },
  watch: {
    order(c) {
      this.$nextTick(() => {
        this.getPoly();
      });
    },
  },
  async mounted() {
    this.showMap = true;

    this.$nextTick(() => {
      this.map = this.$refs.myMap.mapObject;

      let points = this.order.points.map((item) => {
        return latLng(item.latitude, item.longitude);
      });

      this.map.fitBounds(points, {
        paddingTopLeft: [23, 23],
        paddingBottomRight: [23, 23],
      });

      this.getPoly();
      // this.routeControl = L.Routing.control({
      //   router:L.Routing.osrmv1({
      //     // serviceUrl: "https://routing.ocho.life/route/v1",
      //     serviceUrl: "https://us-central1-el-ocho.cloudfunctions.net/httpGetPolygon",
      //     // profile: "driving",
      //     timeout: 30 * 1000,
      //     routingOptions: {
      //       alternatives: false,
      //       steps: true,
      //     },
      //     polylinePrecision: 5,
      //     useHints: true,
      //     suppressDemoServerWarning: false,
      //     language: "es",
      //   }),

      //   lineOptions: {
      //     styles: [
      //       { color: "#00cdbc", opacity: 1, weight: 3, className: "route" },
      //     ],
      //     addWaypoints: false,
      //   },
      //   altLineOptions: {
      //     styles: [
      //       {
      //         color: "#00cdbc",
      //         opacity: 1,
      //         weight: 5,
      //         className: "routeAlt",
      //       },
      //     ],
      //     addWaypoints: false,
      //   },
      //   autoRoute: false,
      //   routeWhileDragging: false,
      //   loading: false,
      // }).addTo(this.map);

      // this.routeControl.setWaypoints(points);
      // this.routeControl.route();

      // this.showPoints();

      // this.businessLogo = L.icon({
      //   className: "motorista",
      //   iconUrl:
      //     this.order && this.order.businessLogo
      //       ? this.order.businessLogo.original
      //       : require("@/assets/driver-02.svg"),
      //   iconSize: [64, 64],
      //   iconAnchor: [32, 64],
      // });
    });
  },
};
</script>

<style lang="scss" >
.control-zoom_ {
  background-color: #ff5900;
}

.leaflet-pane,
.leaflet-marker-icon,
.leaflet-tile-container,
.leaflet-zoom-box,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50% !important;
}

.leaflet-left {
  top: none !important;
  position: fixed !important;
  left: 0 !important;
  top: 10vh !important;
}

.btn-refresh {
  position: fixed;
  top: calc(10vh + 140px);
  left: 10px;
  z-index: 1000;
}

.leaflet-bottom {
  top: none !important;

  left: 0 !important;
  top: 10px !important;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none !important;
  background-clip: padding-box;
  width: 56px;
  border-radius: 20px !important;
  overflow: hidden;
  box-shadow: #07061331 0px 0px 20px !important;

  .leaflet-control-zoom-in {
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .leaflet-control-zoom-out {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
  }
}

.leaflet-control-attribution {
  display: none !important;
}

.leaflet-popup-close-button {
  margin-top: 9px;
  font-weight: bold;
  font-size: 20px;
}

.routeAlt {
  box-shadow: #ff5900 0px 10px 10px;
}

.leaflet-routing-container {
  display: none;
}

.motorista {
  transition: 0.5s ease;
}

.route {
  filter: drop-shadow(2px 0px 4px #00cdbc) !important;
}
.marker-custom {
  filter: drop-shadow(2px 0px 4px #00cdbc) !important;
}
</style>