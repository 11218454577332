

<template>
  <!-- v-if="bt_descarga" -->

  <v-dialog
    v-model="dialog"
    class="dialogo"
    width="300"
    style="border-radius: 20px !important;"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="bt_descarga"
        depressed
        elevation="0"
        large
        :class="className"
        @click="descargar"
      >
        <!-- @click="descargar" -->
        <b class="descarga"> <slot name="pulpito"></slot> Descarga la App </b>
      </v-btn>
      <v-btn
        v-else
        depressed
        elevation="0"
        large
        :class="className"
        v-bind="attrs"
        v-on="on"
      >
        <!-- @click="descargar" -->
        <b class="descarga"> <slot name="pulpito"></slot> Descarga la App </b>
      </v-btn>
    </template>
    <v-card style="border-radius: 20px">
      <div class="code_qr">
        <img
          src="@/assets/qr_img.png"
          class="code_qr"
          width="100%"
          alt=""
          srcset=""
        />
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import store from "@/store/index.js";
export default {
  name: "Descarga",
  props: {
    className: String,
    text: String,
  },

  data() {
    // this.$i18n.locale = store.state.activo;
    return {
      dialog: false,
      bt_descarga: "",
      text_btn: store.state.activo,
    };
  },
  mounted() {
    this.bt_descarga = screen.width <= 800 ? true : false;
  },
  methods: {
    descargar() {
      if (navigator.platform === "iPhone") {
        window.location.href =
          "https://apps.apple.com/hn/app/ocho/id1471212294";
      } else {
        window.location.href =
          "https://play.google.com/store/apps/details?id=io.beanar.ocho&hl=es_419&gl=US";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.descarga {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-dark {
  background-color: rgb(131, 131, 131);
  border: none;
  border-radius: 1em !important;
}
.code_qr {
  border-radius: 2em !important;
  overflow: hidden;
  height: 100%;
  background-color: #fff !important;
}
.dialogo {
  border-radius: 2em !important;
}
.v-dialog {
  border-radius: 20px !important;
}
</style>

