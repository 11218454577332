<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: "App",
  components: {
  
  },

  data: () => ({
    //
  }),

  mounted() {
    
    // console.log(document.domain);
  }
};
</script>

<style lang="scss" scoped>
.app-bar {
  background-color: transparent !important;

}
</style>