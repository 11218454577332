<template>
  <div class="motorista">
    <h3>Motorista</h3>
    <p>{{ order.driver.name }}</p>
    <div class="wrap">
      <a :href="`tel:${order.driver.phone}`" style="background-color: #00cdbc">
        <img src="@/assets/phone.svg" height="20px" />
      </a>
      <a @click="openWh" style="background-color: #25d366">
        <img src="@/assets/WhatsApp.svg" height="20px" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "contact",
  props: {
    dark: {
      typeof: Boolean,
      default: true,
    },
    order: {
      typeof: Object,
    },
  },
  data() {
    return {};
  },

  methods: {
    openWh() {
      let phone = this.order.driver.phone
        .replace("+", "")
        .replaceAll(" ", "")
        .replaceAll("-", "");

      window.open(
        `https://wa.me/${phone}/?text=Hola, tengo una consulta de mi orden.`,
        "_top"
      );
    },
  },
  mounted() {},
};
</script>


<style scoped lang="scss">
.motorista {
  width: 100%;
  height: auto;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .wrap {
    display: flex;
    justify-content: space-around;

    align-items: center;
    width: 100%;
    a {
      height: 40px;
      width: 40px;

      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
